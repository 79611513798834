.utm-wrapper {
    // min-height: 500px;

    .utm-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        margin: 0 0 16px 0;
        color: #191919;
        padding: 8px 0 12px;
        border-bottom: solid 1px #dcdcdc;
    }

    .utm-description {
        margin: 8px 0 16px;
        font-size: 16px;
        letter-spacing: -0.3px;
        width: calc(100% - 230px);
        color: #191919;
        line-height: 24px;
    }

    .add-utm-button {
        // height: 40px;
        // line-height: 23px;
        // padding: 0;
        // width: 195px;
        text-align: center;
        position: absolute;
        top: 32px;
        right: 32px;

        // svg {
        //     vertical-align: top;
        //     font-size: 24px;
        //     position: relative;
        //     top: -1px;
        //     margin-right: 4px;
        // }
    }

    .utm-data-outer-wrapper {
        position: relative;
        // padding: 12px 0 0;
        .custom-filter {
            font-size: 16px;
            letter-spacing: -0.3px;
            border-radius: 8px;
            padding: 12px 16px;
            width: 171px;
            border: solid 1px #dcdcdc;
            cursor: pointer;
            margin: 0 0 12px;

            svg {
                margin-right: 6px;
                font-size: 18px;
                position: relative;
                top: -1px;
            }
        }
    } 

    .utm-data-wrapper {
        width: 100%;
        color: #191919;
        overflow-x: auto;
        min-height: 200px;

        .no-utm {
            width: 100%;
            text-align: center;
            font-size: 20px;
            letter-spacing: -0.6px;
            color: rgba(25, 25, 25, 0.6);
        }

        table {
            width: 100%;
            min-height: 200px;

            thead {
                font-size: 14px;
                border-bottom: solid 1px #dcdcdc;
                color: #191919;
                letter-spacing: -0.4px;

                td {
                    padding: 0 8px 8px;
                    width: 264px;
                    white-space: nowrap;

                    &:nth-child(6) {
                        width: 102px;
                    }

                    &:last-child {
                        width: 90px;
                    }
                }
            }

            tbody {
                font-size: 14px;
                letter-spacing: -0.2px;
                color: #191919;

                tr {
                    border-bottom: solid 1px #dcdcdc;
                }

                td {
                    padding: 16px 10px;
                    vertical-align: top;

                    &.col_action {
                        font-size: 14px;
                        letter-spacing: -0.4px;

                        .edit-button {
                            color: #ff7d00;
                            cursor: pointer;
                            margin-right: 16px;
                        }

                        .remove-button {
                            color: #ff3f57;
                            cursor: pointer;
                        }

                        svg {
                            font-size: 16px;
                            position: relative;
                            top: -2px;
                            margin-right: 4px;
                        }
                    }
                }
            }
        }
    }
}

.modal-custom-filter {
    .modal-content {
        width: 410px;
        border-radius: 8px;

        .modal-body {
            padding: 24px;

            .custom-filter-wrapper {
                margin: 12px 0 24px;
            }

            .advanced-date-selection {
                button {
                    width: 100%;
                }
            }

            .custom-filter-title-icon {
                font-size: 26px;
                position: relative;
                top: -4px;
                margin-right: 4px;
            }

            .custom-filter-title {
                font-size: 24px;
                letter-spacing: -0.6px;
            }

            .group-by-wrapper {
                b { 
                    font-size: 14px;
                    letter-spacing: -0.4px;
                }
        
                .checkbox-wrapper {
                    width: 100%;
                    border-radius: 8px;
                    border: solid 1px #dcdcdc;
                    font-size: 14px;
                    margin: 8px 0 12px;
                    letter-spacing: -0.2px;
        
                    label {
                        margin: 0;
                        line-height: 21px;
                    }
        
                    .custom-checkbox {
                        width: 100%;
                        padding: 8px 12px;
                        height: 40px;
                    }
            
                    .custom-checkbox-stripe {
                        width: 100%;
                        padding: 9px 12px;
                        height: 40px;
                    }
        
                    .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
                        width: 16px;
                        height: 16px;
                        top: 2px;
                        left: 0;
                      }
            
                    .select-all-group {
                        border-bottom: solid 1px #dcdcdc;
                    }
                }
            }

            .main-button-40 {
                width: 100%;
            }
        }
    }
}