@font-face {
  font-family: "PTRootUI";
  src: url("font/PTRootUI.eot");
  src: url("font/PTRootUI.eot") format("embedded-opentype"),
    url("font/PTRootUI.woff2") format("woff2"),
    url("font/PTRootUI.woff") format("woff"),
    url("font/PTRootUI.ttf") format("truetype"),
    url("font/PTRootUI.svg#PTRootUIWeb-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PTRootBold";
  src: url("font/PT Root UI_Bold.ttf");
}

@font-face {
  font-family: "PTRootMedium";
  src: url("font/PT Root UI_Medium.ttf");
}

@font-face {
  font-family: "SFPropBold";
  src: url("font/SFPro/SF-Pro-Text-Bold.otf");
}

@font-face {
  font-family: "SFPro";
  src: url("font/SFPro/SF-Pro-Text-Regular.otf");
}

@font-face {
  font-family: "NeueBold";
  src: url("font/neue/NeueEinstellung-Bold.ttf");
}

@font-face {
  font-family: "NeueMedium";
  src: url("font/neue/NeueEinstellung-Normal.ttf");
}

@font-face {
  font-family: "LatoBold";
  src: url("font/lato/Lato-Bold.ttf");
}

@font-face {
  font-family: "LatoMedium";
  src: url("font/lato/Lato-Light.ttf");
}

:root {
  --main-light-red: #ff7e8e;
  --main-dark-red: #ff3f57;
  --main-dark-orange: #ff7e00;
  --main-darker-red: #e83b51;
  --main-darker-orange: #e87200;
  --main-light-orange: #fdcb56;
  --main-light-grey: #cecece;
  --main-dark-grey: #9b9b9b;
  --main-darker-grey: #a39990;
  --watermelon: #ff3f57;
  --watermelon-dark: #e9001d;
  --main-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.18),
    0px 0px 21px 0px rgba(0, 0, 0, 0.1);
  /* --button-vertical-padding-large: 13px 0 14px; */
}

body {
  margin: 0;
  padding: 0;
  font-family: PTRootUI !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b,
h1,
h2,
h3 {
  font-family: PTRootBold !important;
  font-weight: 500 !important;
  margin: 0;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.font-root-medium {
  font-family: PTRootMedium !important;
}

.grey-font {
  color: rgba(25, 25, 25, 0.4) !important;
}

.grey-font-6 {
  color: rgba(25, 25, 25, 0.6) !important;
}

.absolute-right-0 {
  position: absolute;
  right: 0;
}

.reactstrap-tooltip {
  .tooltip-inner {
    font-size: 14px !important;
    letter-spacing: -0.4px;
    border-radius: 4px;
    line-height: 18px;
    background: red;
  }
}

.cursor-default {
  cursor: default !important;
}

input,
select,
textarea,
button {
  font-family: PTRootUI;
  outline: unset !important;
}

.disabled-input {
  background: rgba(25, 25, 25, 0.1);
  color: rgba(25, 25, 25, 0.4);
  font-size: 16px;
  letter-spacing: -0.3px;
  border-radius: 8px;
  vertical-align: top;
  display: inline-block;
  height: 48px;
  padding: 12px 16px;
}

button {
  transition: ease-in-out 0.3s;
}

a {
  text-decoration: none !important;
}

a.orange {
  color: #ff7e00;
  text-decoration: underline !important;
}

code {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, Courier, monospace !important;
}

mono {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, Courier, monospace !important;
}

.input-warning {
  color: red;
  margin: 0;
  font-size: 13px;
  padding-bottom: 16px;
}

.color-red {
  color: #ff3f57;
}

.no-background-and-border-button {
  border: 0;
  background: 0;
  padding: 0;
}

.no-fill-button {
  border: solid 1px var(--main-dark-orange) !important;
  color: var(--main-dark-orange) !important;
  background: transparent;
  outline: none;
  box-shadow: none !important;
  border-radius: 8px;
  font-weight: bold;
}

.no-fill-button:hover {
  background-color: rgba(255, 126, 0, 0.05);
}

.no-fill-button-grey {
  border: solid 1px rgba(25, 25, 25, 0.4) !important;
  color: rgba(25, 25, 25, 0.4) !important;
  background: transparent;
  outline: none;
  box-shadow: none !important;
  border-radius: 8px;
  font-weight: bold;
}

.no-fill-button-grey:hover {
  border: solid 1px rgba(25, 25, 25, 0.6) !important;
  color: rgba(25, 25, 25, 0.6) !important;
}

.no-fill-button-grey-no-hover {
  border: solid 1px rgba(25, 25, 25, 0.6) !important;
  color: rgba(25, 25, 25, 0.6) !important;
  background: transparent;
  outline: none;
  box-shadow: none !important;
  border-radius: 8px;
  font-weight: bold;
}

.no-fill-button-no-border {
  border: none !important;
  color: var(--main-dark-orange) !important;
  background: transparent;
  outline: none;
  box-shadow: none !important;
  border-radius: 8px;
  font-weight: bold;
}

.no-fill-button-no-border:hover {
  color: var(--main-darker-orange) !important;
}

.no-fill-button:active {
  background-color: rgba(255, 126, 0, 0.18);
}

.no-fill-button-red {
  border: solid 1px #ff3f57 !important;
  color: #ff3f57 !important;
  background: transparent;
  outline: none;
  box-shadow: none !important;
  border-radius: 8px;
  font-weight: bold;

  &:hover {
    background-color: rgba(255, 126, 0, 0.05);
  }

  &:active {
    background-color: rgba(255, 126, 0, 0.18);
  }

  &[disabled] {
    &:hover {
      &:hover {
        background: rgba(25, 25, 25, 0.1) !important;
        color: rgba(25, 25, 25, 0.4) !important;

        svg {
          color: rgba(25, 25, 25, 0.4) !important;
        }
      }
    }
  }
}

.orange-button {
  background: linear-gradient(#ff9833, var(--main-dark-orange));
  color: white !important;
  border: solid 1px var(--main-dark-orange) !important;
  outline: none;
  /* box-shadow: none !important; */
  border-radius: 8px;
  transition: east-in-out 0.2s;
  font-weight: bold;

  svg {
    color: white !important;
  }
}

.orange-button:hover {
  background: var(--main-dark-orange);
  box-shadow: 0 12px 12px 0 rgba(255, 126, 0, 0.2),
    0 0 12px 0 rgba(255, 126, 0, 0.1);
}

.orange-button:active {
  background: var(--main-darker-orange);
  box-shadow: 0 6px 6px 0 rgba(255, 126, 0, 0.2),
    0 0 6px 0 rgba(255, 126, 0, 0.1);
}

.no-fill-black-button {
  background: transparent;
  color: rgba(25, 25, 25, 1) !important;
  border: solid 1px rgba(25, 25, 25, 0.1) !important;
  outline: none;
  box-shadow: 0px 4px 10px 0px rgba(131, 119, 198, 0.1);
  border-radius: 8px;
  transition: east-in-out 0.2s;
  font-weight: bold;

  svg {
    color: rgba(25, 25, 25, 1) !important;
  }
}

.no-fill-black-button:hover {
  // border: solid 1px rgba(25, 25, 25, 1) !important;
}

.light-orange-button {
  background: #FFF2E5;
  color: #ff7d00 !important;
  border: solid 1px #FFF2E5 !important;
  outline: none;
  /* box-shadow: none !important; */
  border-radius: 8px;
  transition: east-in-out 0.2s;
  font-weight: bold;

  svg {
    position: relative;
    top: -1px;
    margin-right: 4px;
    color: #ff7d00;
  }

  &:hover {
    background: #ff7d00;
    color: #ffffff !important;
    box-shadow: 0 12px 12px 0 rgba(255, 126, 0, 0.2),
      0 0 12px 0 rgba(255, 126, 0, 0.1);

    svg {
      color: #ffffff !important;
    }
  }

  &[disabled] {
    &:hover {
      color: rgba(25, 25, 25, 0.4) !important;

      svg {
        color: rgba(25, 25, 25, 0.4) !important;
      }
    }
  }
}

.orange-button-disabled {
  background: linear-gradient(#ff9833, var(--main-dark-orange));
  color: white !important;
  border: solid 1px var(--main-dark-orange) !important;
  outline: none;
  /* box-shadow: none !important; */
  border-radius: 8px;
  transition: east-in-out 0.2s;
  font-weight: bold;
}

.blue-button {
  background-color: rgba(4, 75, 132, 1);
  color: #ffffff;
  border-radius: 8px;
  margin-top: 8px;
  font-size: 14px;

  &:hover {
    background-color: rgba(4, 75, 132, .9);
  }

  svg {
    color: #ffffff;
  }
}

.no-fill-blue-button {
  background-color: #fff;
  color: rgba(4, 75, 132, 1);
  border-radius: 8px;
  margin-top: 8px;
  font-size: 14px;
  border: solid 1px rgba(4, 75, 132, 1);

  &:hover {
    background-color: #fcfcfc;
  }

  svg {
    color: #ffffff;
  }
}

.red-button {
  background: #ff3f57;
  color: white !important;
  border: solid 1px var(--main-dark-red) !important;
  outline: none;
  /* box-shadow: none !important; */
  border-radius: 8px;
  transition: east-in-out 0.2s;
  font-weight: bold;

  svg {
    color: white !important;
  }
}

.no-fill-button-red {
  border: solid 1px var(--main-dark-red) !important;
  color: var(--main-dark-red) !important;
  background: transparent;
  outline: none;
  box-shadow: none !important;
  border-radius: 8px;
  font-weight: bold;
}

.no-fill-button-red:hover {
  background-color: var(--main-dark-red) !important;
  color: #fff !important;
}

.red-button-transparent {
  background: transparent;
  color: var(--main-dark-red) !important;
  border: solid 1px var(--main-dark-red) !important;
  outline: none;
  /* box-shadow: none !important; */
  border-radius: 8px;
  transition: east-in-out 0.2s;
  font-weight: bold;

  svg {
    color: var(--main-dark-red) !important;
  }

  &[disabled] {
    background: transparent !important;
    color: rgba(25, 25, 25, 0.4) !important;
    border: solid 1px rgba(25, 25, 25, 0.4) !important;

    &:hover {
      background: transparent !important;
    }
  }
}

.red-button.solid {
  background: var(--main-dark-red);
}

.red-button:hover {
  /* box-shadow: 0 12px 12px 0 rgba(255, 63, 87, 0.2),
    0 0 12px 0 rgba(255, 63, 87, 0.1); */
  background: #e83b51;
}

.red-button-no-fill {
  color: #FF3F57;
  background: transparent;
  outline: none;
  /* box-shadow: none !important; */
  border-radius: 8px;
  transition: east-in-out 0.2s;
  font-weight: bold;

  &:hover {
    background: #FAEEEE;
  }

  svg {
    color: #FF3F57;
  }
}

.green-button {
  background: #19c700;
  border: solid 1px #19c700 !important;
  color: #ffffff !important;
  outline: none;
  border-radius: 8px;
  transition: east-in-out 0.2s;
  font-weight: bold;

  svg {
    color: #ffffff !important;
  }
}

.green-button:hover {
  background: #19B503;
  color: #ffffff !important;
}

.yellow-button {
  background: #ffb438;
  color: #ffffff !important;
}

.yellow-button:hover {
  background: #f8ad30;
  color: #ffffff !important;
}

.red-button:active {
  background: #e83b51;
}

.gray-button-disabled {
  background: #1919191A !important;
  color: #19191966 !important;
  border: none !important;
  outline: none;
  border-radius: 8px;
  transition: east-in-out 0.2s;
  font-weight: bold;

  svg {
    color: #19191966 !important;
  }
}

.action-button {
  display: flex;
  align-items: center;
  padding: 4px;
  margin: 0 6px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: var(--main-dark-orange) !important;
  background: transparent;
  border: none;
  font-weight: 600;
}

.action-button * {
  margin: 0 4px;
}

.no-fill-button,
.orange-button,
.red-button {
  font-family: PTRootBold;
}

.no-fill-button.wide-button,
.orange-button.wide-button,
.red-button.wide-button {
  min-width: 300px !important;
}

.action-button.del {
  color: var(--watermelon) !important;
}

button[disabled] {
  color: rgba(25, 25, 25, 0.4) !important;
  border: solid 1px #E8E8E8 !important;
  background: rgba(25, 25, 25, 0.1) !important;
  cursor: not-allowed;
  box-shadow: none !important;

  svg {
    color: rgba(25, 25, 25, 0.4) !important;
  }

  &:hover {
    // color: rgba(25, 25, 25, 0.4) !important;
    // border: solid 1px #E8E8E8 !important;
    // background: rgba(25, 25, 25, 0.1) !important;
    // cursor: not-allowed;
    // box-shadow: none !important;

    // svg {
    //   color: rgba(25, 25, 25, 0.4) !important;
    // }
  }
}

.orange-text {
  color: #ff7e00;
  cursor: pointer;

  &:hover {
    color: var(--main-darker-orange) !important;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.no-result-found {
  color: rgba(25, 25, 25, 0.6) !important;
}

.custom-switch-wrapper {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 42px;
}

.custom-switch-wrapper input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-switch-wrapper .custom-switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom-switch-wrapper .custom-switch:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 8px;
  bottom: 8px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 2px 4px grey;
}

input:checked+.custom-switch {
  background-color: #19C300;
}

input:focus+.custom-switch {
  box-shadow: 0 0 1px #79c028;
}

input:checked+.custom-switch:before {
  transform: translateX(29px);
}

/* Rounded custom-switchs */
.custom-switch.round {
  border-radius: 34px;
  margin: 0 !important;
}

.custom-switch.round:before {
  border-radius: 50%;
}

.custom-checkbox [type="checkbox"]:not(:checked),
.custom-checkbox [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

.custom-checkbox [type="checkbox"]:not(:checked)+label,
.custom-checkbox [type="checkbox"]:checked+label {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
}

.custom-checkbox [type="checkbox"]:not(:checked):disabled+label,
.custom-checkbox [type="checkbox"]:checked:disabled+label {
  cursor: not-allowed;
}

.custom-checkbox [type="checkbox"]:not(:checked)+label:before,
.custom-checkbox [type="checkbox"]:checked+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  border: 1px solid #dcdcdc;
  background: #ffffff;
  border-radius: 4px;
}

.custom-checkbox [type="checkbox"]:not(:checked)+label:after,
.custom-checkbox [type="checkbox"]:checked+label:after {
  content: "";
  background-image: url("../img/icon-for-checkbox.svg");
  position: absolute;
  /* content: "\f007"; */
  // font-family: FontAwesome;
  // font-weight: 900;
  background-size: cover;
  background-repeat: no-repeat;
  top: -3px;
  left: -5px;
  width: 26px;
  height: 26px;
  // background: var(--main-dark-orange);
  font-size: 10px;
  line-height: 17px;
  text-align: center;
  color: white;
  border-radius: 4px;
  font-weight: normal;
  // transition: 0.5s ease-in-out;
}

.custom-checkbox-circle {
  label {
    &::after {
      background-image: url("../img/icon-for-checkbox-circle.svg") !important;
    }
  }
}

.custom-checkbox [type="checkbox"]:not(:checked):disabled+label:after,
.custom-checkbox [type="checkbox"]:checked:disabled+label:after {
  background-image: url("../img/icon-for-checkbox-disabled.svg");
  background-size: 21px 21px;
  /* width: 18px; */
  top: 0px;
  left: -2px;
}

.custom-checkbox-stripe [type="checkbox"]:not(:checked):disabled+label:after,
.custom-checkbox-stripe [type="checkbox"]:checked:disabled+label:after {
  background-image: url("../img/icon-for-checkbox-stripe-disabled.svg") !important;
  background-size: 21px 21px;
  /* width: 18px; */
  top: 0px;
  left: -2px;
}

.custom-checkbox [type="checkbox"]:not(:checked)+label:after {
  opacity: 0;
  transform: scale(0);
}

.custom-checkbox [type="checkbox"]:checked+label:after {
  opacity: 1;
  transform: scale(1);
}

.custom-checkbox label:hover:before {
  border: 2px solid var(--main-dark-orange) !important;
}

.custom-checkbox input[disabled]+label:hover:before {
  border: 1px solid #dcdcdc !important;
}

.custom-checkbox-stripe [type="checkbox"]:not(:checked),
.custom-checkbox-stripe [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

.custom-checkbox-stripe [type="checkbox"]:not(:checked)+label,
.custom-checkbox-stripe [type="checkbox"]:checked+label {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
}

.custom-checkbox-stripe [type="checkbox"]:not(:checked):disabled+label,
.custom-checkbox-stripe [type="checkbox"]:checked:disabled+label {
  cursor: not-allowed;
}

.custom-checkbox-stripe [type="checkbox"]:not(:checked)+label:before,
.custom-checkbox-stripe [type="checkbox"]:checked+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  border: 1px solid #dcdcdc;
  background: #ffffff;
  border-radius: 4px;
}

.custom-checkbox-stripe [type="checkbox"]:not(:checked)+label:after,
.custom-checkbox-stripe [type="checkbox"]:checked+label:after {
  content: "";
  background-image: url("../img/icon-for-checkbox-stripe.svg");
  background-position: center;
  position: absolute;
  /* content: "\f007"; */
  // font-family: FontAwesome;
  // font-weight: 900;
  background-size: cover;
  background-repeat: no-repeat;
  top: -3px;
  left: -5px;
  width: 26px;
  height: 26px;
  // background: var(--main-dark-orange);
  font-size: 10px;
  line-height: 17px;
  text-align: center;
  color: white;
  border-radius: 4px;
  font-weight: normal;
  // transition: 0.5s ease-in-out;
}

.custom-checkbox-stripe-circle {
  label {
    &::after {
      background-image: url("../img/icon-for-checkbox-circle.svg") !important;
    }
  }
}

.custom-checkbox-stripe [type="checkbox"]:not(:checked):disabled+label:after,
.custom-checkbox-stripe [type="checkbox"]:checked:disabled+label:after {
  background-image: url("../img/icon-for-checkbox-disabled.svg");
  background-size: 21px 21px;
  /* width: 18px; */
  top: 0px;
  left: -2px;
}

.custom-checkbox-stripe [type="checkbox"]:not(:checked)+label:after {
  opacity: 0;
  transform: scale(0);
}

.custom-checkbox-stripe [type="checkbox"]:checked+label:after {
  opacity: 1;
  transform: scale(1);
}

.custom-checkbox-stripe label:hover:before {
  border: 2px solid var(--main-dark-orange) !important;
}

.custom-checkbox-white [type="checkbox"]:not(:checked),
.custom-checkbox-white [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

.custom-checkbox-white [type="checkbox"]:not(:checked)+label,
.custom-checkbox-white [type="checkbox"]:checked+label {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
}

.custom-checkbox-white [type="checkbox"]:not(:checked):disabled+label,
.custom-checkbox-white [type="checkbox"]:checked:disabled+label {
  cursor: not-allowed;
}

.custom-checkbox-white [type="checkbox"]:not(:checked)+label:before,
.custom-checkbox-white [type="checkbox"]:checked+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  border: 1px solid #dcdcdc;
  background: #ffffff;
  border-radius: 4px;
}

.custom-checkbox-white [type="checkbox"]:not(:checked)+label:after,
.custom-checkbox-white [type="checkbox"]:checked+label:after {
  content: "";
  background-image: url("../img/icon-for-checkbox-white.svg");
  position: absolute;
  /* content: "\f007"; */
  // font-family: FontAwesome;
  // font-weight: 900;
  background-size: cover;
  background-repeat: no-repeat;
  top: -3px;
  left: -5px;
  width: 26px;
  height: 26px;
  // background: var(--main-dark-orange);
  font-size: 10px;
  line-height: 17px;
  text-align: center;
  color: white;
  border-radius: 4px;
  font-weight: normal;
  // transition: 0.5s ease-in-out;
}

.custom-checkbox-white [type="checkbox"]:not(:checked):disabled+label:after,
.custom-checkbox-white [type="checkbox"]:checked:disabled+label:after {
  background-image: url("../img/icon-for-checkbox-disabled.svg");
  background-size: 21px 21px;
  /* width: 18px; */
  top: 0px;
  left: -2px;
}

.custom-checkbox-white [type="checkbox"]:not(:checked)+label:after {
  opacity: 0;
  transform: scale(0);
}

.custom-checkbox-white [type="checkbox"]:checked+label:after {
  opacity: 1;
  transform: scale(1);
}

.custom-checkbox-white label:hover:before {
  border: 2px solid var(--main-dark-orange) !important;
}

.custom-tapTalk-card {
  border-radius: 8px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.18);
  background-color: #ffffff;
}

.custom-radio [type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.custom-radio label {
  margin-bottom: 0;
}

.custom-radio [type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  display: inline-block;
  color: #191919;
}

.custom-radio [type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 4px;
  width: 16px;
  height: 16px;
  border: solid 1px #dcdcdc;
  border-radius: 100%;
  background: #fff;
}

.custom-radio label:hover {
  &::before {
    border: solid 1px var(--main-dark-orange);
  }
}

.custom-radio-error [type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 4px;
  width: 16px;
  height: 16px;
  border: solid 1px rgba(255, 63, 87, 1) !important;
  border-radius: 100%;
  background: #fff;
}

.custom-radio [type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: "";
  width: 16px;
  height: 16px;
  border: solid 5px var(--main-dark-orange);
  position: absolute;
  top: 4px;
  left: 0;
  border-radius: 100%;
  transition: all 0.3s ease;
}

.custom-radio.custom-radio-disabled [type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: "";
  width: 16px;
  height: 16px;
  border: solid 5px #e8e8e8;
  position: absolute;
  top: 4px;
  left: 0;
  border-radius: 100%;
  transition: all 0.3s ease;
}

.custom-radio.custom-radio-inactive [type="radio"]:not(:checked)+label:after {
  content: "";
  width: 14px;
  height: 14px;
  border: none;
  position: absolute;
  top: 5px;
  left: 1px;
  border-radius: 50%;
  transition: all 0.3s ease;
  transform: scale(1);
  opacity: 1;
}

.custom-radio [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.custom-radio [type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
  top: -3px;
  right: -3px;
}

.lds-ring.centered-lds-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-ring.centered-lds-ring div {
  margin: 0;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  margin: 6px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--main-dark-orange) transparent transparent transparent;
}

.lds-ring.white-lds-ring div {
  border-color: #ffffff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.lds-ring-wrapper {
  width: 100%;
  text-align: center;

  .lds-ring {
    top: 3px;
    right: unset;

    div {
      margin: 0;
    }
  }
}

.modal-backdrop.show {
  background: #191919 !important;
}

.modal-dialog {
  /* width: 100vw !important; */
  margin: 0 auto !important;
  height: 100vh;
}

.modal-content {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  box-shadow: var(--main-box-shadow);
}

.taptalk-form label {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: #191919;
  display: block;
}

.taptalk-form input[type="text"] {
  padding: 10px 15px 12px;
  border-radius: 8px;
  border: solid 1px #dcdcdc;
  margin: 10px 0 15px;
  font-size: 16px;
  letter-spacing: -0.2px;
  color: #191919;
  position: relative;
}

/*table with checkbox*/
.custom-table {
  position: relative;
  max-width: 100%;
  overflow-x: auto;

  &.custom-table-show-overflow {
    overflow: visible !important;
  } 
}

.custom-table-content {
  width: 100%;
  border-collapse: collapse;
}

.custom-table-content tr td {
  padding: 8px;
  position: relative;
  white-space: nowrap;
}

.custom-table-content tr td>.custom-checkbox {
  display: inline-block;
  position: relative;
  top: -7px;
  margin-right: 15px;
}

.custom-table-content tr td>.custom-checkbox-stripe {
  display: inline-block;
  position: relative;
  top: -7px;
  margin-right: 15px;
}

.custom-table-content thead tr {
  background: #f8f8f8;
}

.custom-table-content thead tr td {
  padding: 8px;
  font-size: 14px;
  letter-spacing: -0.4px;
}

.custom-table-content tbody tr:hover td {
  background: rgba(255, 126, 0, 0.15);
}

.custom-table-content tr {
  border-bottom: solid 1px #dcdcdc;
  background: #ffffff;
}

// .custom-table-content tr:nth-child(even) {
//   background: #f8f8f8;
// }

.custom-table-content thead {
  font-weight: bold;
}

.custom-table-content tbody tr td {
  /* cursor: pointer; */
  font-weight: 500;
  position: relative;
  max-width: 300px;
  white-space: normal;
  vertical-align: top;
  font-size: 14px;
  letter-spacing: -0.2px;
}

.custom-table-content tbody tr td.no-data-column {
  text-align: center;
  color: #a6a6a6;
  background: none !important;
  padding: 24px 0;
  border: none !important;
}

tr {
  &.no-data-column {
    border: none !important;

    &:nth-child(odd) td {
      background: none !important;
    }
  }
}

.custom-table-content tbody tr td.action {
  width: 50px;
}

.custom-table-content tbody tr td svg {
  font-size: 22px;
  color: var(--main-dark-orange);
  position: absolute;
  right: 6px;
  top: 18px;
}

.custom-table-content tbody tr td.email div {
  display: inline-block;
  position: relative;
  top: -7px;
  margin-right: 16px;
}

.custom-table-content tbody tr td.col_action {
  width: 50px;
}

.custom-table-content tbody tr td.col_action div {
  display: none;
}

.custom-table-content tbody tr:hover td.col_action div {
  display: block;
}

.custom-table-content .resend-invitation {
  padding: 3px 25px;
}

/*table with checkbox*/

.custom-dropdown button.dropdown-toggle {
  border: solid 1px #dcdcdc !important;
  background: #ffffff !important;
  color: #191919 !important;
  border-radius: 8px;
  text-align: left;
  position: relative;
  width: 285px;
  box-shadow: none !important;
  padding: 9px 60px 10px 15px;
}

.custom-dropdown button.dropdown-toggle::after {
  transform: rotate(-45deg);
  border-left: solid 1px #191919;
  border-bottom: solid 1px #191919;
  border-right: 0;
  border-top: 0;
  height: 7px;
  width: 7px;
  position: absolute;
  top: 17px;
  right: 18px;
}

.custom-dropdown button.dropdown-toggle::before {
  content: "";
  width: 45px;
  position: absolute;
  height: 44px;
  top: 0;
  right: 0;
  background: transparent;
  border-left: solid 1px #dcdcdc;
}

.custom-dropdown .dropdown-menu {
  width: 285px;
  border-radius: 8px;
  margin-top: 0;
  padding: 0;
}

.custom-dropdown .dropdown-menu .dropdown-item {
  background: transparent !important;
  color: #191919 !important;
  position: relative;
}

.custom-dropdown .dropdown-menu button {
  border: none !important;
  border-bottom: solid 1px #dcdcdc !important;
  padding: 10px 0 10px 46px;
}

.custom-dropdown .dropdown-menu button:nth-last-child(1) {
  border: none !important;
}

.custom-dropdown .dropdown-menu .dropdown-item.active-dropdown-list {
  color: var(--main-dark-orange) !important;
}

.custom-dropdown .dropdown-menu .dropdown-item.active-dropdown-list::before {
  content: "";
  position: absolute;
  left: 17px;
  top: 50%;
  transform: translate(0, -50%);
  width: 15px;
  height: 10px;
  background-position: center;
  background-size: 20px;
  background-image: url("../../assets/img/check-mark.png");
}

.custom-date-picker .DayPicker-Caption {
  position: relative;
}

.custom-date-picker .DayPicker-wrapper {
  border: none !important;
  box-shadow: none !important;
  outline: 0 !important;
}

.custom-date-picker .DayPicker-wrapper .DayPicker-Month {
  margin: 16px 12px 0;
}

.custom-date-picker .DayPicker-Caption div {
  font-weight: bold !important;
  text-align: center;
}

.custom-date-picker span.DayPicker-NavButton.DayPicker-NavButton--prev {
  position: absolute;
  display: inline-block;
  margin: 0;
  left: 27px;
  top: 20px;
  z-index: 1;
  width: 20px;
  height: 20px;
  background-image: none;
}

.custom-date-picker span.DayPicker-NavButton.DayPicker-NavButton--prev::before {
  position: absolute;
  top: 4px;
  left: 0;
  content: "";
  border-left: solid 2px var(--main-dark-orange);
  border-bottom: solid 2px var(--main-dark-orange);
  transform: rotate(45deg);
  width: 12px;
  height: 12px;
}

.custom-date-picker span.DayPicker-NavButton.DayPicker-NavButton--next {
  position: absolute;
  display: inline-block;
  margin: 0;
  right: 19px;
  top: 20px;
  z-index: 1;
  width: 20px;
  height: 20px;
  background-image: none;
}

.custom-date-picker span.DayPicker-NavButton.DayPicker-NavButton--next::before {
  position: absolute;
  top: 4px;
  left: 0;
  content: "";
  border-right: solid 2px var(--main-dark-orange);
  border-bottom: solid 2px var(--main-dark-orange);
  transform: rotate(-45deg);
  width: 12px;
  height: 12px;
}

.custom-date-picker .DayPicker-Day {
  font-weight: bold;
  width: 40px;
  height: 40px;
  text-align: center;
}

.custom-date-picker .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: var(--main-dark-orange);
}

.custom-date-picker .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: var(--main-dark-orange);
}

/* single date picker */

/* .recharts-layer path {
	fill: red;
} */
/* custom chart */

.input-file-wrapper input {
  display: none;
}

.input-file-wrapper label {
  padding: 12px 35px;
  display: inline-block !important;
  margin: 10px 0 24px !important;
}

.input-file-wrapper label svg {
  position: relative;
  top: -1px;
  margin-right: 4px;
  cursor: pointer;
}

.sort-by-dropdown .dropdown .dropdown-toggle {
  background: transparent !important;
  color: var(--main-dark-orange) !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  font-weight: bold;
}

.sort-by-dropdown .dropdown .dropdown-toggle:hover {
  color: var(--main-darker-orange) !important;
}

.sort-by-dropdown .dropdown .dropdown-toggle::after {
  position: relative;
  top: 1px;
  margin-left: 8px;
}

.go-back-wrapper a {
  color: var(--main-dark-orange) !important;
  display: block;
  padding-bottom: 15px;
}

.go-back-wrapper a svg {
  position: relative;
  top: -1px;
}

.box-extra-info {
  border-radius: 8px;
  padding: 11px 16px 11px 52px;
  font-size: 16px;
  color: #191919;
  display: block;
  position: relative;
}

.box-extra-info.info-blue {
  background: #dcebff;
  color: #044b84;
}

.box-extra-info.info-brown {
  background: #fdd984;
}

.box-extra-info svg {
  font-size: 28px;
  position: absolute;
  left: 16px;
  top: 9px;
}

.box-extra-info p {
  margin: 0;
}

.modal.fade.show {
  width: 100vw;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(25, 25, 25, 0.6);
}

.modal-footer>* {
  margin: unset;
}

.modal-backdrop.show {
  background: #191919 !important;
}

.modal-dialog {
  /* width: 100vw !important; */
  margin: 0 auto !important;
  height: 100vh;
  position: relative !important;
  top: 0;
  left: 0;
  max-width: unset !important;
  width: 500px !important;
}

.modal-content {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  box-shadow: var(--main-box-shadow);
  background: #ffffff;
}

.manage-project-tool-wrapper {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  margin-top: 0;
  padding: 0;
  border: none;
  height: 46px;
  margin-top: 0;
  padding: 0;
}

.manage-project-tool-wrapper #button-box-wrapper {
  text-align: right;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
}

.manage-project-tool-wrapper #button-box-wrapper button {
  margin-left: 10px;
  padding: 10px 30px;
}

.typing-dot-one {
  opacity: 0;
  animation: typing-dot 1.3s infinite;
  animation-delay: 0.0s;
  letter-spacing: 1px;
}

.typing-dot-two {
  opacity: 0;
  animation: typing-dot 1.3s infinite;
  animation-delay: 0.2s;
  letter-spacing: 1px;
}

.typing-dot-three {
  opacity: 0;
  animation: typing-dot 1.3s infinite;
  animation-delay: 0.3s;
  letter-spacing: 1px;
}

@keyframes typing-dot {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.message-body {
  word-break: break-word;
}

.button-padding-with-icon {
  padding: 8px 32px 8px 24px;
}

.button-padding-without-icon {
  padding: 8px 24px;
}

.user-avatar-name {
  text-transform: uppercase;
  border-radius: 50%;
}

.button-flex {
  margin-top: 0 !important;
}

.form-title-wrapper {
  padding-bottom: 16px;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1);
  color: #191919;
  font-size: 20px;
  letter-spacing: -0.6px;
}

.form-content-wrapper {
  .input-info-wrapper {
    margin-top: 8px;
    font-size: 12px;
    letter-spacing: -0.4px;
    color: rgba(0, 0, 0, 0.6);

    svg {
      margin-right: 4px;
      position: relative;
      top: -1px;
      font-size: 14px;
    }
  }

  .disabled-form {
    position: relative;
    display: inline-block;

    input {
      background: #f3f3f3 !important;
      border: 1px solid rgba(25, 25, 25, 0.1) !important;
      color: rgba(25, 25, 25, 0.6) !important;
    }

    svg {
      color: rgba(25, 25, 25, 0.4);
      font-size: 20px;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translate(0, -50%);
      cursor: pointer;

      &:hover {
        color: #ff7e00;
      }
    }
  }
}

.form-content-wrapper-label16 {
  label {
    font-size: 16px !important;
    letter-spacing: -0.5px !important;
  }
}

.border-warning-red {
  border: 1px solid #FF3F57 !important;
}

.warning-text {
  color: rgba(255, 63, 87, 1) !important;
}

.form-content-wrapper label {
  display: block;
  color: #191919;
  font-size: 16px;
  margin-top: 24px;
  letter-spacing: -0.5px;

  label {
    display: block;
    color: #191919;
    font-size: 14px;
    margin-top: 24px;

    &.label-disabled {
      color: rgba(25, 25, 25, 0.2);
    }

    .input-label-optional {
      color: rgba(25, 25, 25, 0.6);
    }
  }
}

.form-content-wrapper input[type="text"] {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid rgba(25, 25, 25, 0.1);
  height: 48px;
  width: 348px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: 316px;
  max-width: 100%;
  padding: 0 16px;
  caret-color: var(--main-dark-orange);
}


.form-content-wrapper textarea {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid rgba(25, 25, 25, 0.1);
  min-height: 48px;
  width: 348px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: 316px;
  max-width: 100%;
  padding: 12px 16px;
  line-height: 24px;
  resize: none;
  caret-color: var(--main-dark-orange);
}

.form-content-wrapper label {
  display: block !important;
  letter-spacing: -0.4px;
}

.form-content-wrapper .role-radio-label {
  position: relative;
}

.form-content-wrapper .role-radio-label b {
  display: block;
  color: #191919;
  font-size: 14px;
  letter-spacing: -0.4px;
}

.form-content-wrapper .role-radio-label label {
  color: rgba(25, 25, 25, 0.8);
  font-size: 14px;
  letter-spacing: -0.2px;
}

.form-content-wrapper .custom-radio label {
  margin-top: 18px;
}

.form-content-wrapper button {
  width: 300px;
  padding: 10px 0 11px;
  margin-top: 32px;
}

.form-content-wrapper .orange-button .lds-ring {
  top: 3px;
  right: unset;
}

.form-content-wrapper .orange-button .lds-ring div {
  border-color: #ffffff transparent transparent transparent;
  margin: 0;
}

.form-content-wrapper.disabled-field label {
  color: rgba(25, 25, 25, 0.2) !important;
}

.form-content-wrapper.disabled-field input[type="text"] {
  color: rgba(25, 25, 25, 0.6);
  background: #E8E8E8;
  cursor: not-allowed;
}

.form-content-wrapper input[type="text"]:focus,
.form-content-wrapper textarea:focus {
  border: solid 1px #ff7e00;
}

input[type="text"],
textarea,
textarea {
  &[disabled] {
    color: rgba(25, 25, 25, 0.8) !important;
    background: #ebebeb !important;
    border: solid 1px rgba(25, 25, 25, 0.1) !important;
  }
}

.caret-white {
  caret-color: #ffffff;
}

.alert-component {
  border-radius: 8px;
  position: relative;
  padding: 12px 48px;
  letter-spacing: -0.5px;
  font-size: 16px;
  display: inline-block;
  vertical-align: top;

  svg {
    position: absolute;
    font-size: 24px;
    left: 16px;
    top: 12px;
  }

  img {
    position: absolute;
    font-size: 24px;
    left: 16px;
    top: 50%;
    transform: translate(0, -50%);
  }

  &.alert-blue {
    color: rgba(12, 95, 153, 1);
    background: #EBF7FF;
    border: solid 1px rgba(12, 95, 153, 1);
  }

  &.alert-orange {
    color: var(--main-dark-orange);
    // border: solid 1px var(--main-dark-orange);
    background: rgba(255, 242, 229, 1);
  }
}

.alert-component-whatsapp {
  border-radius: 8px;
  padding: 13px 144px 13px 10px;
  color: #044B84;
  background: #DCEBFF;
  border: solid 1px #044B84;
  margin: 24px 0 12px;
  line-height: 1;
  position: relative;
  display: inline-block;

  .alert-component-left-icon {
    position: relative;
    top: -1px;
    font-size: 18px;
    margin-right: 4px;
  }

  img {
    position: relative;
    margin: 0 4px 0 6px;
    top: -2px;
    width: 15px;
    height: 15px;
  }

  button {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);
    height: 26px;
    font-size: 12px;
    letter-spacing: -0.4px;
    border: none;
    color: #ffffff;
    background: rgba(4, 75, 132, 1);
    border-radius: 4px;
    padding: 0 12px;
    line-height: 24px;

    svg {
      font-size: 13px;
      margin-left: 4px;
      position: relative;
    }

    &:hover {
      background: rgba(4, 75, 132, .8);
    }
  }
}

.main-content-loading-screen {
  .lds-ring {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    right: unset;

    div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0;
    }
  }
}

.step-container {
  .step-content {
    display: inline-block;

    .step-number {
      width: 18px;
      height: 18px;
      text-align: center;
      line-height: 18px;
      font-size: 12px;
      letter-spacing: -0.4px;
      color: #ffffff;
      background: rgba(25, 25, 25, 0.4);
      border-radius: 50%;
      display: inline-block;
    }

    .step-wording {
      font-size: 14px;
      color: #19191966;
      letter-spacing: -0.4px;
      display: inline-block;
      position: relative;
      top: 1px;
      margin-left: 6px;
    }

    &.orange-step {
      .step-number {
        background: #ff7d00;
      }

      .step-wording {
        color: #ff7d00;
      }
    }
  }

  .step-line {
    height: 2px;
    width: 20px;
    display: inline-block;
    margin: 0 12px;
    background: rgba(25, 25, 25, 0.4);
    position: relative;
    top: -2px;

    &.step-line-orange {
      background: #ff7d00;
    }
  }
}

.arrow::before {
  border-bottom: solid 5px #6B6B6B !important;
}

.tooltip-inner {
  font-size: 14px;
  letter-spacing: -0.2px;
  text-align: left !important;
  line-height: 20px;
  padding: 6px 12px !important;
  background: #6B6B6B !important;
}

.no-margin {
  margin: 0 !important;
}

.link-orange {
  color: #ff7d00;

  &:hover {
    color: #e87200;
  }
}

.grey-text {
  color: rgba(25, 25, 25, 0.6) !important;
}

.grey-text-04 {
  color: rgba(25, 25, 25, 0.4) !important;
}

.red-text {
  color: rgba(255, 63, 87, 1) !important;

  svg {
    color: rgba(255, 63, 87, 1) !important;
  }

  b {
    color: rgba(255, 63, 87, 1) !important;
  }
}

.red-background {
  background: rgba(255, 63, 87, 1) !important;
}

.green-text {
  color: rgba(25, 199, 0, 1) !important;
}

.green-background {
  background: rgba(25, 199, 0, 1) !important;
}

.blue-text {
  color: #3BAEFF !important
}

.orange-text {
  color: var(--main-dark-orange) !important;
}

.yellow-text {
  color: #F4C12A !important;
}

.yellow-background {
  background: #F4C12A !important
}

.facebook-button {
  width: 278px;
  border-radius: 8px;
  border: none;
  background: #3678EA;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: -0.5px;

  &:hover {
    background: #166fe5;
  }
}

.channel-button-wrapper {
  b {
    letter-spacing: -0.5px;
    font-size: 16px;

    &.channel-button-title {
      font-size: 16px;
      letter-spacing: -0.5px;
    }

    &.status-value {
      font-size: 16px;
    }
  }
}

.orange-link-text {
  color: var(--main-dark-orange) !important;
  cursor: pointer;

  svg {
    position: relative !important;
    top: -2px !important;
    margin-right: 4px;
    color: var(--main-dark-orange) !important;
  }

  &:hover {
    color: var(--main-darker-orange) !important;

    svg {
      color: var(--main-darker-orange) !important;
    }
  }
}

// .deleted-icon {
//   top: 3px !important;
//   vertical-align: top !important;
// }
.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.input-info-text {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.8);
  margin: 8px 0 16px;
  width: 320px;
  position: relative;
  padding: 0 0 0 16px;

  svg {
    position: absolute;
    left: 0;
    top: 3px;
    margin-right: 4px;
  }

  &.no-margin-bottom {
    margin: 8px 0 0;
  }
}

.no-margin {
  margin: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.width-100 {
  width: 100% !important;
}

.topic-list-content {
  color: rgba(25, 25, 25, 0.6);
  font-size: 12px;
  letter-spacing: -.4px;
  border-radius: 4px;
  background: rgba(25, 25, 25, 0.05);
  margin: 8px 4px 0 0;
  display: inline-block;
  padding: 2px 8px;
  vertical-align: top;
  max-width: 100%;
  word-break: break-word;

  .remove-topic {
    display: inline-block;
    color: #9c9c9c;
    font-size: 18px;
    margin-left: 4px;
    position: relative;
    top: 4px;
    cursor: pointer;
    font-size: 8px;
    background: #9a9a9a;
    width: 10px;
    height: 10px;
    color: #ffffff;
    border-radius: 50%;
    vertical-align: top;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      background: #818181;
    }
  }

  &.topic-list-with-remove {
    white-space: nowrap;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 2px 28px 2px 8px;

    .remove-topic {
      position: absolute;
      right: 8px;
      top: 6px;
    }
  }
}

.remove-bulk {
  display: inline-block;
  color: #9c9c9c;
  font-size: 18px;
  margin-left: 4px;
  position: relative;
  top: 4px;
  cursor: pointer;
  font-size: 8px;
  background: #9a9a9a;
  width: 10px;
  height: 10px;
  color: #ffffff;
  border-radius: 50%;
  vertical-align: top;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background: #818181;
  }
}

.margin-0 {
  margin: 0 !important;
}

.margin-top24 {
  margin-top: 24px;
}

.padding-bottom24 {
  padding-bottom: 24px !important;
}

.text-green {
  color: #19c700 !important;
}

.text-left {
  text-align: left !important;
}

@keyframes taplivePlaceholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.taplive-shine {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;

  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: taplivePlaceholderShimmer;
  animation-timing-function: linear;
}

.force-hide {
  display: none !important;
}

.disabled-span {
  color: rgba(25, 25, 25, 0.4) !important;
}

::placeholder {
  color: rgba(25, 25, 25, 0.3);
}

.text-capitalize {
  text-transform: capitalize;
}

.font-orange {
  color: #ff7d00 !important;
}

.font-red {
  color: #ff3f57 !important;

  svg {
    color: #ff3f57 !important;
  }
}

.font-green {
  color: #19C700 !important;
}

.font-yellow {
  color: #EDAB00 !important;
}

.font-grey {
  color: rgba(25, 25, 25, 0.4);
}

.border-red {
  border-color: #ff3f57 !important;

  .react-select__control {
    border: solid 1px #ff3f57 !important;
  }

  .dropdown {
    .custom-select-option-value-wrapper {
      border: solid 1px #ff3f57 !important;
    }
  }
}

.margin-bottom-8 {
  margin-bottom: 8px !important;
}

.margin-top-0 {
  margin-top: 0px !important;
}

.margin-top-8 {
  margin-top: 8px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-12 {
  margin-top: 12px !important;
}

.margin-top-14 {
  margin-top: 14px !important;
}

.margin-top-24 {
  margin-top: 24px !important;
}

.margin-top-32 {
  margin-top: 32px !important;
}


.margin-bottom-24 {
  margin-bottom: 24px !important;
}

.display-block {
  display: block !important;
}

.right-0 {
  right: 0 !important;
  left: unset !important;
}

[placeholder] {
  text-overflow: ellipsis;
}

.separator-line-grey {
  background: rgba(25, 25, 25, 0.1);
  width: 100%;
  display: block;
  height: 1px;
}

.color-707070 {
  color: #707070 !important;
}

.no-data-found {
  color: rgba(25, 25, 25, 0.6);
  letter-spacing: -0.6px;
  font-size: 16px;
}

.grey-box {
  background: #ebebeb !important;
}

.pos-relative {
  position: relative;
}

.modal-add-topic {
  .popup-header {
    margin-bottom: 16px !important;

  }

  label {
    font-size: 14px;
    position: relative;
    width: 100%;
  }
}

.absolute-top-right-0 {
  position: absolute;
  top: 0;
  right: 0;
}

.react-select-custom__single-value {
  color: #191919 !important;
}

.react-select-custom__input {
  color: #191919 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.line-through {
  height: 1px;
  background: #dcdcdc;
  width: 100%;
}

.no-hover {
  cursor: default !important;
}

.colored-info-box {
  white-space: nowrap !important;
}

.pos-relative {
  position: relative !important;
}

.optional-text {
  color: rgba(0, 0, 0, 0.6) !important;
  font-family: 'PTRootUI' !important;
  position: relative !important;
  right: unset !important;
}

.font16 {
  font-size: 16px !important;
  letter-spacing: -0.5px !important;
}

.font14 {
  font-size: 14px !important;
  letter-spacing: -0.4px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-uppercase {
  text-transform: uppercase;
}

.form-content-wrapper {
  input[type="text"] {
    &.border-red {
      border-color: #ff3f57 !important;
    }
  }

  textarea[type="text"] {
    &.border-red {
      border-color: #ff3f57 !important;
    }
  }
}

.dropdown-select-item-per-page {
  .dropdown {
    width: 74px;
    height: 30px;

    button {
      position: relative;
      border-radius: 4px !important;
      height: 30px;
    }

    svg {
      position: absolute !important;
      top: 15px !important;
      right: 6px !important;
      font-size: 18px;
    }
  }
}

.custom-select2-wrapper {
  border: none !important;
  background: none !important;

  input[type="text"] {
    &:focus {
      border: none !important;
      background: none !important;
    }
  }

  input[type="text"] {
    border: none !important;
    background: none !important;

    &:disabled {
      border: none !important;
      background: none !important;
    }

    &:focus {
      border: none !important;
      background: none !important;
    }
  }
}

.green-background {
  background: #19C700 !important;
}

.orange-background {
  background: #FF7E00 !important;
}

.yellow-background {
  background: #F4C12A !important;
}

.red-background {
  background: #E02E2E !important;
}

.blue-background {
  background: rgba(12, 95, 153, 1) !important;
}

.badge-text {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.6);
  display: inline-block;
  border-radius: 4px;
  padding: 2px 8px;
  white-space: nowrap;
  color: #ffffff;
}

@keyframes taplivePlaceholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.onetalk-shine {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%) !important;
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;

  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: taplivePlaceholderShimmer;
  animation-timing-function: linear;
}

.border-red {
  .react-select-custom__control {
    border-color: #ff3f57 !important;
  }
}

.no-overflow {
  overflow: hidden !important;
}

@import "buttonStyle.scss";
@import "emojiMart.scss";